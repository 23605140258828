import { GetIpAddress, GetLatLngByIP } from "../services/restGeoLocation";

import { useEffect, useState } from "react";

const useGeoLocation = (
  useGPS = true,
  useIPAddress = true,
  ip = null,
  ready = false
) => {
  const defaultState = {
    loaded: false,
    hasError: false,
    error: {},
    coordinates: { lat: 0, lng: 0 },
  };

  const clearGeoLocation = () => {
    setLocation({ ...defaultState, loaded: true });
  };

  const [geoLocation, setLocation] = useState(defaultState);

  const onSuccess = async (location) => {
    console.log("GPS Location", location);

    let dataResult = null;
    let country = null;
    let currentIP = null;

    try {
      dataResult = await GetIpAddress();
    } catch (error) {
      console.error(error);
    }

    if (dataResult) {
      // const resultArray = dataResult.split("\n");
      country = dataResult.country; // resultArray[8].replace("loc=", "");
      currentIP = dataResult.ip;
    }

    setLocation({
      loaded: true,
      hasError: false,
      error: {},
      coordinates: {
        country: country,
        lat: location.coords.latitude,
        lng: location.coords.longitude,
        ip: currentIP,
      },
    });
    console.log("geoLocation ", geoLocation);
  };

  const onError = async (error) => {
    console.log("Use Location onError");
    if (navigator.userAgent.indexOf("Chrome-Lighthouse") > -1) {
      return;
    }

    // if (useGPS) {
    //   setLocation({
    //     loaded: true,
    //     hasError: true,
    //     error: "GPS was denied",
    //     coordinates: { lat: 0, lng: 0 },
    //   });

    //   return;
    // }

    console.log(error.code, error.message);

    let ipResult = ip;
    let userGeoLocation;
    // setLocation(defaultLocation);

    if (ip) {
      userGeoLocation = await GetLatLngByIP(ipResult);
    } else {
      userGeoLocation = await GetIpAddress();
    }

    console.log("userGeoLocation v2: ", userGeoLocation);

    if (!userGeoLocation) {
      setLocation({
        loaded: true,
        hasError: true,
        error: "Error trying to get your location by ip",
        coordinates: { lat: 0, lng: 0 },
      });

      console.error("Error trying to get your location by ip", ipResult);
      return;
    }

    console.log("userGeoLocation v3: ", userGeoLocation);
    // const resultArray = dataResult.split("\n");
    // const ipResult = resultArray[2].replace("ip=", "");
    // const userGeoLocation = await GetLatLngByIP(ipResult);
    setLocation({
      loaded: true,
      hasError: false,
      error: {},
      coordinates: {
        lat: userGeoLocation.lat, // Number(coordinates[3]) || 40.73061,
        lng: userGeoLocation.lon, // Number(coordinates[4]) || -73.935242,
        postalCode: userGeoLocation.postalCode, // string e.g. 01010
        country: userGeoLocation.country, // country abbr e.g. US
        countryName: userGeoLocation.countryName, // country name e.g. Guatemala
        city: userGeoLocation.city, // city name e.g. Miami
        state: userGeoLocation.state, // state abbr e.g. FL
        stateName: userGeoLocation.stateName, // state name e.g. Florida
        ip: userGeoLocation.ip,
      },
    });
    console.log("geoLocation ", geoLocation);
    // } else {
    //   setLocation({
    //     loaded: true,
    //     hasError: true,
    //     error: {
    //       code: error.code,
    //       message: error.message,
    //     },
    //     coordinates: {
    //       lat: 40.73061,
    //       lng: -73.935242,
    //     },
    //   });
    // }
  };

  useEffect(() => {
    console.log("usegeolocation");
    if (!ready) return;

    // if (!useGPS && !useIPAddress && !ip) {
    //   clearGeoLocation();
    //   return;
    // }

    if (geoLocation.coordinates.lat || geoLocation.coordinates.lng) return;

    if (!("geolocation" in navigator)) {
      onError({ code: -1, message: "Geolocation not supported" });
      console.log("Geolocation not supported");
      return;
    }

    if (!useGPS && (useIPAddress || ip)) {
      onError({ code: -1, message: "Ip detection preferer" });
      return;
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, [
    useGPS,
    useIPAddress,
    geoLocation.coordinates.lat,
    geoLocation.coordinates.lng,
    ready,
    ip,
  ]);

  return [geoLocation, clearGeoLocation];
};

export default useGeoLocation;

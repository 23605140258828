import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DoSearch2 } from "../services/restSearchService";
import { buildKiosksArray } from "../utils/ecoAtmUtils";
import { getCloseInformation } from "../utils/utils";

export function useDoSearch2(searchParameters, onSuccess) {
  const [doSearch2State, setdoSearch2State] = useState({
    locations: [],
    doSearch2SearchReqLogId: 0,
    loading: true,
  });
  const { t } = useTranslation();
  /*const [doSearch2State, setdoSearch2State] = useState([]);
  const [doSearch2SearchReqLogId, setdoSearch2SearchReqLogId] = useState(0);*/

  useEffect(() => {
    setdoSearch2State({
      locations: [],
      doSearch2SearchReqLogId: 0,
      loading: true,
    });

    const doSearch2 = async () => {
      const locations = await DoSearch2(
        1000,
        searchParameters.countryId,
        searchParameters.latitude,
        searchParameters.longitude,
        searchParameters.state,
        searchParameters.city,
        searchParameters.pageSize,
        searchParameters.userIPAddress
      );

      const results = locations.ResultList;
      results.forEach((element) => {
        if (element.DailyHoursList) {
          element.openCloseInfo = t(
            "openCloseHours." + element.OpenCloseStatus
          );

          /*getCloseInformation(
            element.DailyHoursList,
            element.TimeZoneOffset ? element.TimeZoneOffset : element.TimeZone
          );*/
        }
        element.kiosks = buildKiosksArray(
          element.Attributes,
          "/images/us-payment-type-icon.svg"
        );
      });

      setdoSearch2State({
        locations: results,
        doSearch2SearchReqLogId: locations.SearchReqLogId,
        loading: false,
      });
      // setdoSearch2SearchReqLogId(locations.SearchReqLogId);

      onSuccess();
    };

    if (
      searchParameters.latitude ||
      searchParameters.longitude ||
      searchParameters.state ||
      searchParameters.city
    ) {
      doSearch2();
    }
  }, [searchParameters, onSuccess]);

  return doSearch2State;
}

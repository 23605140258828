export const SEARCH_RES_LOG_TYPE = {
  urlClicked: 1,
  emailClicked: 2,
  locationResultsClicked: 3,
  locationMapClicked: 4,
  directionsClicked: 5,
  promotionPrint: 6,
  promotionShare: 7,
  locationPromotionPrint: 8,
  attributeClicked: 9,
};

export const EVENT_LOG_TYPE = {
  logSearch: 1,
};

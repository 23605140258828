import React from "react";
import LabelWithIcon from "../../labelWithIcon";

const KioskPlacement = ({ kiosksArray }) => {
  return (
    <>
      {kiosksArray.map(({ alert, label, labelPayment }, index) => {
        // Remove temporary the payment type
        labelPayment = null;
        return (
          <div key={index}>
            <hr className="mt-1 mb-2" />
            {alert && (
              <div
                className={`alert alert-sm rounded-0 mt-2 ${alert.cssClass}`}
                role="alert"
              >
                <small>{alert.title}</small>
              </div>
            )}
            {labelPayment && (
              <div className="mt-3 ecoatm-grey">
                <LabelWithIcon
                  containerClass="d-flex"
                  title={
                    <div>
                      <span className="bl-text-bold-500">
                        {labelPayment.title}:
                      </span>{" "}
                      {labelPayment.value}
                    </div>
                  }
                  btIcon={{ size: 20 }}
                  customIconUrl={labelPayment.icon}
                />
              </div>
            )}
            {label && (
              <div className="mt-3 ecoatm-grey">
                <LabelWithIcon
                  containerClass="d-flex"
                  title={
                    <div>
                      <span className="bl-text-bold-500">{label.title}:</span>{" "}
                      {label.value}
                    </div>
                  }
                  btIcon={{ btIconName: label.icon, size: 20 }}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default KioskPlacement;

import axios from "axios";
// import globalConfigProp from "../config.json";

// const getIPAddressURL = "https://www.cloudflare.com/cdn-cgi/trace"; this url caused problems with ublock
const getIPAddressURL = `${process.env.REACT_APP_URL_WRAPPER}/GeoConfiguration/GetRemoteIpAddress`;
const getGeoCodeFromIPURL = `${process.env.REACT_APP_URL_WRAPPER}/GeoConfiguration/GetGeoLocationIP?ip=`;
// const getGeoCodeFromIPURL = "https://honaero2.bullseyelocations-staging.com/api/map/GetIPLocation?ip=";
// const AddressByIp = "https://geoip.maxmind.com/geoip/v2.1/city";

export async function GetIpAddress() {
  try {
    const ip = await axios.get(getIPAddressURL, { headers: {}, params: {} });
    console.log("Your ip address... ", ip.data);

    return ip.data;
  } catch (error) {
    return null;
  }
}

export async function GetLatLngByIP(ipAddress) {
  //axios.defaults.params = {};

  // var username = 52807;
  // var password = "rs7kk8KIhWND";
  // var basicAuth = "Basic " + btoa(username + ":" + password);
  try {
    const ipResult = await axios.get(`${getGeoCodeFromIPURL}${ipAddress}`);
    // const ipResult = await axios1.get(
    //   `${AddressByIp}/${ipAddress}?referrer=${window.location.protocol}//${window.location.hostname}`,
    //   {
    //     headers: { "Content-Type": "application/vnd.maxmind.com-country+json" },
    //     // Axios looks for the `auth` option, and, if it is set, formats a
    //     // basic auth header for you automatically.
    //     auth: {
    //       username: "52807",
    //       password: "rs7kk8KIhWND",
    //     },
    //   }
    // );

    return ipResult.data;
  } catch (error) {
    console.log("Authenticated", error);
  }

  return null;
}

import GoogleMapReact from "google-map-react";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import GoogleMapMarker from "./googleMapMarker";

const GoogleMap = ({
  markers,
  googleApikey,
  onMapReady,
  onClick,
  markerSelected = 0,
  showLandingPageLink = true,
  containerRef,
}) => {
  const createMapOptions = () => {
    return {
      // minZoom: 8,
      maxZoom: 15,
      fullscreenControl: !(window.screen.width < 768),
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "landscape.natural.landcover",
          elementType: "geometry.stroke",
          stylers: [{ color: "#535353" }, { weight: "0.50" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#bfe6f6" }, { visibility: "on" }],
        },
      ],
    };
  };

  const [initPositionMap] = useState({
    center: {
      lat: markers.length > 0 ? markers[0].Latitude : 41.850033,
      lng: markers.length > 0 ? markers[0].Longitude : -87.6500523,
    },
    zoom: 3,
  });

  const [gmap, setGmap] = useState();
  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, markers, isLoaded = false) => {
    if (onMapReady) {
      onMapReady(map, maps, markers);
    }
    if (!isLoaded) {
      setGmap(map);
    }
  };

  // new bounce whe the map was loaded already
  useEffect(() => {
    // Fit map to bounds
    if (gmap && markers.length > 0 && markerSelected < 1) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(
          new window.google.maps.LatLng(marker.Latitude, marker.Longitude)
        );
      });
      if (markers.length === 1) {
        gmap.setZoom(15);
        gmap.panTo({ lat: markers[0].Latitude, lng: markers[0].Longitude });
      } else {
        gmap.fitBounds(bounds);
      }
    }
    if (markers && markerSelected > 1) {
      const marker = markers.find((obj) => obj.Id === markerSelected);
      if (marker) {
        // for large screen we will pan to center, for small on the bottom of the map
        // the containerRef is the div that contains the map
        if (containerRef && window.screen.width < 768) {
          let divHeightOfTheMap = containerRef.current.clientHeight;
          let offSetFromBottom = 125;

          gmap.setCenter({ lat: marker.Latitude, lng: marker.Longitude });
          gmap.panBy(0, -(divHeightOfTheMap / 2 - offSetFromBottom));
        } else {
          gmap.panTo({ lat: marker.Latitude, lng: marker.Longitude });
        }
      }
    }
  }, [markers, gmap, markerSelected]);

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleApikey,
          libraries: ["places"],
        }}
        defaultCenter={initPositionMap.center}
        defaultZoom={initPositionMap.zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, markers)}
        showInfo={true}
      >
        {markers.map((marker) => (
          <GoogleMapMarker
            key={marker.Id}
            Id={marker.Id}
            lat={marker.Latitude}
            lng={marker.Longitude}
            pin="/images/beIcon.png"
            marker={marker}
            className={`googleMarker-${marker.Id}`}
            showInfoWindow={markerSelected === marker.Id}
            showLandingPageLink={showLandingPageLink}
            onClick={(marker) => {
              /** function to send the Id selected to the parent */
              if (onClick) onClick(marker.Id);
            }}
          ></GoogleMapMarker>
        ))}
      </GoogleMapReact>
    </>
  );
};
GoogleMap.propTypes = {
  /** List of location with at least the following atributes */
  markers: PropTypes.arrayOf(
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** location Id */
      Id: PropTypes.number.isRequired,
      /** coords of location */
      Latitude: PropTypes.number.isRequired,
      Longitude: PropTypes.number.isRequired,
      /** name of the location */
      Name: PropTypes.string.isRequired,
      /** address of the location */
      Address1: PropTypes.string.isRequired,
      /** city of the address */
      City: PropTypes.string.isRequired,
      /** state of city */
      State: PropTypes.string,
      /** postal code of the location */
      PostCode: PropTypes.string,
      /** country of this location */
      Country: PropTypes.string,
      /** url of the lading page detail of this location */
      URL: PropTypes.string,
    })
  ),
  /** Google Api Key to get map */
  googleApikey: PropTypes.string.isRequired,
  /** callback to set the map is ready */
  onMapReady: PropTypes.func.isRequired,
  /** function to on click on one item to the list will be selected into the parent */
  onClick: PropTypes.func,
  /** if the parent send some item pre selected */
  markerSelected: PropTypes.number,
};
export default GoogleMap;

import React from "react";
import { useTranslation } from "react-i18next";

const EcoAtmFooterAction = () => {
  const { t } = useTranslation();
  return (
    <div className="text-md-left text-center">
      {/* image of EcoAtm*/}

      <img
        src="https://cdn.shopify.com/s/files/1/0098/6955/7815/t/10/assets/eco-white-logo.svg?11245"
        alt="ecoatm logo"
      />

      <ul className="nav list-inline m-3 justify-content-md-start justify-content-center text-white">
        <li className="list-inline-item mr-3">
          <a href="https://www.facebook.com/ecoATM">
            <svg className="mr-2" width={25} height={25}>
              <use xlinkHref={`/images/b-icons/bootstrap-icons.svg#facebook`} />
            </svg>
          </a>
        </li>
        <li className="list-inline-item mr-3">
          <a href="https://www.instagram.com/ecoatm/">
            <svg className="mr-2" width={25} height={25}>
              <use
                xlinkHref={`/images/b-icons/bootstrap-icons.svg#instagram`}
              />
            </svg>
          </a>
        </li>
        <li className="list-inline-item mr-3">
          <a href="https://twitter.com/ecoATM">
            <svg className="mr-2" width={25} height={25}>
              <use xlinkHref={`/images/b-icons/bootstrap-icons.svg#twitter`} />
            </svg>
          </a>
        </li>
        <li className="list-inline-item mr-3">
          <a href="https://www.youtube.com/user/ecoATMvideos">
            <svg className="mr-2" width={25} height={25}>
              <use xlinkHref={`/images/b-icons/bootstrap-icons.svg#youtube`} />
            </svg>
          </a>
        </li>
      </ul>

      {/*footer caption*/}
      <div className="p-2">
        <h5 className="text-success">{t("footer.priceYourDevice")}</h5>

        {/*App Download*/}
      </div>
      <ul className="nav list-inline justify-content-md-start justify-content-center">
        <li className="p-1 nav-item">
          <a href="https://itunes.apple.com/us/app/ecoatm/id944835823?ls=1&mt=8">
            <img
              alt="Apple iTunes"
              src="https://cdn.shopify.com/s/files/1/0162/3841/1830/files/F868A635-CDDD-44BD-9490-DAB313F6585B_small.png?v=1564125182"
            ></img>
          </a>
        </li>

        <li className="p-1 nav-item">
          <a href="https://play.google.com/store/apps/details?id=com.ecoatm.ecoapp.android">
            <img
              alt="Google Play"
              src="https://cdn.shopify.com/s/files/1/0162/3841/1830/files/5067485F-2B6F-440D-9D96-D0CBD9D7DAF8_small.png?v=1564125198"
            ></img>
          </a>
        </li>
      </ul>
    </div>
  );
};
export default EcoAtmFooterAction;

import React, { useEffect, useState } from "react";
import { getMenuItems } from "../../../services/restGetLocalInformation";
import EcoAtmFooter from "../../ecoATMComponents/ecoAtmFooter/ecoAtmFooter";
import MobileMenu from "../../ecoATMComponents/mobileMenu/mobileMenu";
import EcoMenu from "../../ecoMenu/ecoMenu";
import NotFound from "./notFound";
import UnexpectedError from "./unexpectedError";

const ErrorPage = (props) => {
  //States for menu
  const [menuItems, setMenuItems] = useState({ items: [], fixedMenuItems: [] });
  const errorType = props.match.params.errorType;

  useEffect(() => {
    const setMenuConfigurationItems = async () => {
      const response = await getMenuItems();
      setMenuItems(response);
    };

    setMenuConfigurationItems();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card shadow-sm p-0 mt-1 d-none d-lg-block">
              <EcoMenu menuConfiguration={menuItems} />
            </div>

            <div className="card shadow-sm p-0 mt-1 d-block d-lg-none">
              <MobileMenu menuItems={menuItems} />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div className="my-5 p-2 p-md-5" style={{ width: "70%" }}>
              {errorType && errorType === "not-found" ? <NotFound /> : <></>}
              {errorType && errorType === "unexpected" ? (
                <UnexpectedError />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row main-footer mt-3">
          <div className="col-12">
            <EcoAtmFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;

import http from "./httpService";
const bannerBaseName = "bannerItems";
const menuBaseName = "menuItems";

export async function getBannerItems(language) {
  const fileName =
    bannerBaseName + (!language ? ".en.json" : `.${language}.json`);

  const banners = await http.get(`${window.location.origin}/json/${fileName}`);

  return banners.data;
}

export async function getMenuItems(language) {
  const fileName =
    menuBaseName + (!language ? ".en.json" : `.${language}.json`);

  const menuItems = await http.get(
    `${window.location.origin}/json/${fileName}`
  );
  return menuItems.data;
}

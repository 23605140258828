import React from "react";

const UnexpectedError = () => {
  return (
    <div>
      <h1 className="text-center display-4 mt-4 mb-5 bullseye-green">
        Something Went Wrong
      </h1>
      <p>Unfortunately, the page you have requested has an error.</p>
      <p>
        Please try using the navigation at the top or bottom of this page to
        find the content you are looking for. Or you can go directly to{" "}
        <a href="https://www.ecoatm.com/">ecoATM's homepage.</a>
      </p>
      <p>
        If you've arrived here after clicking a link on our website,{" "}
        <a href="https://www.ecoatm.com/pages/contact-us">please let us know</a>{" "}
        and we'll get things fixed as quickly as possible.
      </p>
    </div>
  );
};

export default UnexpectedError;

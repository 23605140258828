import React from "react";
import { useTranslation } from "react-i18next";
const EcoAtmFooterPolicies = () => {
  const { t } = useTranslation();
  return (
    <div className="text-white" style={{ fontSize: "0.9rem" }}>
      <ul className="nav list-inline">
        <li className="list-inline-item mr-2 pr-2 border-right">
          <a href="https://www.ecoatm.com/pages/privacy-policy">
            <small>{t("footer.privacyPolicy")}</small>
          </a>
        </li>
        <li className="list-inline-item mr-2 pr-2 border-right">
          <a href="https://www.ecoatm.com/pages/terms-and-conditions">
            <small>{t("footer.termsAndConditions")}</small>
          </a>
        </li>
        <li className="list-inline-item mr-2 pr-2 border-right">
          <a href="https://www.ecoatm.com/pages/privacy-policy">
            <small>{t("footer.adChoices")}</small>
          </a>
        </li>
        <li className="list-inline-item mr-2">
          <a href="https://www.ecoatm.com/pages/sitemap">
            <small>{t("footer.siteMap")}</small>
          </a>
        </li>
      </ul>
    </div>
  );
};
export default EcoAtmFooterPolicies;

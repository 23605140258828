import axios1 from "axios";
const urlNewsLetter =
  "https://70ox4iflge.execute-api.us-east-2.amazonaws.com/Prod/email/send/";

export async function postSuscribeEmail(email) {
  axios1.defaults.params = {};
  let postData = {};
  postData.emailAddress = email.trim();
  postData.domain = "ecoatm-footer-triggered-send";
  postData.dataExtension = "";
  postData.triggeredSend = "ecoatm-footer-triggered-send";
  console.log(postData);
  try {
    const result = await axios1.post(urlNewsLetter, postData);
    console.log(result.data);
    return true;
  } catch (error) {
    console.log("error sending subcribe on newsletter", error);
    return false;
  }
}

import React from "react";
import PropTypes from "prop-types";

const BusinessHoursTable = ({ daysArray }) => {
  return (
    <table className="w-100 m-2 ecoatm-grey">
      <tbody>
        {daysArray.map((dayInfo) => {
          return (
            <tr key={dayInfo.day}>
              <th scope="row" className="bl-text-bold-500">
                {dayInfo.day}
              </th>
              <td>{dayInfo.hours}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

BusinessHoursTable.propTypes = {
  /** Days of the week*/
  daysArray: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the day of the week */
      day: PropTypes.string,

      /** hours in format 08:00 AM - 05:00 PM*/
      hours: PropTypes.string,
    })
  ).isRequired,
};

export default BusinessHoursTable;

import i18n from "i18next";
import translationEN from "../languages/en/translationEN.json";
import dataEN from "../languages/en/data.json";
import translationES from "../languages/es/translationES.json";
import dataES from "../languages/es/data.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

/**  for init the component it is necessary to have the order in the instance of LanguageDetector and InitReactI18Next
 Ex.
 i18n
  .use(LanguageDetector) 
  .use(initReactI18next)
  */

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next)
  .init({
    detection: {
      // order and from where user language should be detected
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      load: "languageOnly",
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      // cookieDomain: "myDomain",

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
    resources: {
      en: {
        translation: translationEN,
        data: dataEN,
      },
      es: {
        translation: translationES,
        data: dataES,
      },
    },
    // lng: "en",
    fallbackLng: "en",
    supportedLngs: ["en", "es"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export function getExtraDataByLanguage() {
  const data = i18n.getDataByLanguage(i18n.language).data;
  if (data) return data;
  return null;
}

import { getI18n } from "react-i18next";
import http from "./httpService";

const endpointPath = "RestSearch.svc";
const categoryGetMethod = "GetCategories";
const locationGetMethod = "GetLocation";
const doSearch2GetMethod = "DoSearch2";
const countryListGetMethod = "GetCountryList";
const countryStatesListGetMethod = "GetStatesByCountry";
const getRegion1ListGetMethod = "GetRegion1List";
const logSearchRsltwReq = "LogSearch";
const getClientMappingSettings = "RestSearch.svc/GetClientMappingSettings";

//const ipAdressGetMethod = "https://www.cloudflare.com/cdn-cgi/trace";

function categoryUrl() {
  return `${endpointPath}/${categoryGetMethod}?ReturnAllCategories=true`;
}
function locationUrl(id) {
  // Get current language
  const i18n = getI18n();

  return `${endpointPath}/${locationGetMethod}?LocationId=${id}&LanguageCode=${i18n.language}`;
}
function locationThirdpartyUrl(id) {
  // Get current language
  const i18n = getI18n();

  return `${endpointPath}/${locationGetMethod}?ThirdPartyId=${id}&LanguageCode=${i18n.language}`;
}
function countryListUrl() {
  return `${endpointPath}/${countryListGetMethod}`;
}
function countryStatesListUrl(countryId) {
  return `${endpointPath}/${countryStatesListGetMethod}?CountryId=${countryId}`;
}
function LogSearchRsltwReqURL(
  locationId,
  responseType,
  searchReqLogId,
  attributeId,
  attributeTypeId
) {
  let endpoint = `${endpointPath}/${logSearchRsltwReq}?locationID=${locationId}`;

  if (responseType) {
    endpoint += `&responseType=${responseType}`;
  }

  if (searchReqLogId) {
    endpoint += `&SearchLogReqId=${searchReqLogId}`;
  }

  if (attributeId) {
    endpoint += `&AttributeId=${attributeId}`;
  }

  if (attributeTypeId) {
    endpoint += `&AttributeType=${attributeTypeId}`;
  }

  return endpoint;
}

export async function getCategories() {
  http.setSecurityParameters();

  const categories = await http.get(categoryUrl());
  return categories.data;
}

export async function getSearchLocation(id, thirdPartyId) {
  http.setSecurityParameters();

  if (id) {
    console.log(id);
    const location = await http.get(locationUrl(id));
    return location.data;
  } else {
    console.log(thirdPartyId);
    const location = await http.get(locationThirdpartyUrl(thirdPartyId));
    return location.data;
  }
}

function doSearch2Url(
  countryId,
  postalCode,
  radius,
  startIndex,
  pageSize,
  latitude,
  longitude,
  state,
  city,
  userIPAddress
) {
  // Get current language
  const i18n = getI18n();

  console.log("LanguageCode", i18n.language);

  let endpoint = `${endpointPath}/${doSearch2GetMethod}?FillAttr=true&GetHoursForUpcomingWeek=true&Radius=${radius}&StartIndex=${startIndex}&PageSize=${pageSize}`;

  if (i18n && i18n.language) {
    endpoint += `&LanguageCode=${i18n.language}`;
  }

  if (latitude && longitude) {
    endpoint += `&Latitude=${latitude}&Longitude=${longitude}`;
  }

  if (postalCode) {
    endpoint += `&PostalCode=${postalCode}`;
  }

  if (state) {
    endpoint += `&State=${state}`;
  }

  if (city) {
    endpoint += `&City=${city}`;
  }
  if (countryId) {
    endpoint += `&CountryId=${countryId}`;
  }
  endpoint += `&InterfaceID=${process.env.REACT_APP_INTERFACE_ID}`;

  if (userIPAddress) {
    endpoint += `&userIPAddress=${userIPAddress}`;
  }

  return endpoint;
}

function getRegion1ListUrl(countryId) {
  return `${endpointPath}/${getRegion1ListGetMethod}?CountryId=${countryId}`;
}

export async function DoSearch2(
  radius,
  countryId,
  latitude,
  longitude,
  state,
  city,
  pageSize,
  userIPAddress
) {
  http.setSecurityParameters();

  const locations = await http.get(
    doSearch2Url(
      countryId,
      null,
      radius,
      0,
      pageSize,
      latitude,
      longitude,
      state,
      city,
      userIPAddress
    )
  );
  return locations.data;
}

export async function GetRegion1List(countryId) {
  http.setSecurityParameters();

  const region1List = await http.get(getRegion1ListUrl(countryId));
  return region1List.data;
}

export async function GetCountryList() {
  http.setSecurityParameters();

  const location = await http.get(countryListUrl());
  return location.data;
}
export async function GetCountryStatesList(countryId) {
  http.setSecurityParameters();

  const location = await http.get(countryStatesListUrl(countryId));
  return location.data;
}

export async function LogSearchRsltwReq(
  locationId,
  responseType,
  searchReqLogId,
  attributeId,
  attributeTypeId
) {
  http.setSecurityParameters();
  await http.get(
    `${LogSearchRsltwReqURL(
      locationId,
      responseType,
      searchReqLogId,
      attributeId,
      attributeTypeId
    )}`
  );
}

export async function findClientMappingSettings() {
  http.setSecurityParameters();
  console.log("get Mapping Key");
  const location = await http.get(getClientMappingSettings);
  return location.data;
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Cookies from "universal-cookie";

/** Components */
import SimpleListWithTitle from "../simpleListWithTitle";
import EcoMenu from "../ecoMenu";
import ResponsiveBanner from "../responsiveBanner";
import VideoPlayer from "../videoPlayer";
import GoogleMap from "../googleMap";
import LocationListItems from "../locationListItems";
import GoogleAutocomplete from "../googleAutocomplete";
import Showcase from "../showcase";
import AboutEcoAtm from "../ecoATMComponents/aboutEcoAtm";
// import CovidAnnouncement from "../ecoATMComponents/covidAnnouncement";
import EcoAtmFooter from "../ecoATMComponents/ecoAtmFooter";
import EcoATMSEO from "../ecoATMComponents/ecoATMSEO";
import MobileMenu from "../ecoATMComponents/mobileMenu/mobileMenu";
import AnnouncementCustom from "../ecoATMComponents/announcementCustom";

/** Utilities */
// import { ecoAtmShowcase } from "../../services/json/sampledata";
import { getStateNameByAbbr } from "../../utils/utils";
import useGeoLocation from "../../utils/useGeolocation";
// import useCheckMobileScreen from "../../utils/detectMobile";

/** Services */
import {
  GetRegion1List,
  findClientMappingSettings,
} from "../../services/restSearchService";
import {
  getMenuItems,
  getBannerItems,
} from "../../services/restGetLocalInformation";
import { useDoSearch2 } from "../../store/doSearch2.state";
import { useLocation } from "../../store/location.state";

/** Styles */
import "./listMapLocator.scss";
import { useTracking } from "react-tracking";
import Loading from "../loading/loading";
/** I18 */

import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import { getExtraDataByLanguage } from "../../services/i18n";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const ListMapLocator = (props) => {
  const state = props.match.params.state;
  let city = props.match.params.city;
  const thirdparty = props.match.params.thirdpartyid || "";

  // This is to remove the dash in the url like /ca/san-diego
  if (city) {
    city = city.replace(/-/g, " ");
  }

  const [region1List, setRegion1List] = useState([]);
  const [googleMapKey, setGoogleMapKey] = useState(null);
  const [googleMapIsReady, setGoogleMapIsReady] = useState(false);
  const [markerSelected, setMarkerSelected] = useState(-3);
  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
    ip: null,
  });

  //i18
  const [extraDataByLanguage, setExtraDataByLanguage] = useState();

  //Call hook to get geolocation;
  const getGeoLocation = !(state || thirdparty);

  const [geoLocation] = useGeoLocation(
    getGeoLocation,
    getGeoLocation,
    null,
    true
  );

  useEffect(() => {
    if (geoLocation.coordinates.lat && geoLocation.coordinates.lng) {
      setCoordinates({
        latitude: geoLocation.coordinates.lat,
        longitude: geoLocation.coordinates.lng,
        ip: geoLocation.coordinates.ip,
      });
    }
  }, [geoLocation]);

  const [thirdpartyId, setThirdpartyId] = useState("");
  const locationState = useLocation(null, thirdpartyId);
  const mapContainer = useRef();

  const [searchParameters, setSearchParameters] = useState({
    countryId: null,
    latitude: null,
    longitude: null,
    state: null,
    city: null,
    pageSize: 20,
    userIPAddress: null,
  });

  const { t, i18n } = useTranslation();

  // const [loadMap, setLoadMap] = useState(false);

  // const isMobile = useCheckMobileScreen();

  // if (!isMobile) {
  //   if (!loadMap) {
  //     setLoadMap(true);
  //   }
  // }

  // Look for neaby locations
  useEffect(() => {
    if (autoComplete.current) {
      autoComplete.current.clearGoogleAddress();
    }

    // console.log(
    //   "Search by Thirdparty Id",
    //   locationState.location.Latitude,
    //   locationState.location.Longitude
    // );
    if (
      locationState.location.Latitude !== 0 &&
      locationState.location.Longitude !== 0
    ) {
      setSearchParameters({
        countryId: 1,
        state: null,
        city: null,
        latitude: locationState.location.Latitude,
        longitude: locationState.location.Longitude,
        pageSize: 4,
        userIPAddress: coordinates?.ip,
      });
    }
  }, [locationState, i18n.language]);

  const [menuItems, setMenuItems] = useState({ items: [], fixedMenuItems: [] });

  const [bannersImages, setBannersImages] = useState({
    bannerOne: {},
    bannerTwo: {},
    bannerThree: {},
  });

  //GetBanner
  const getBannerByPos = (bannerList, pos) => {
    const block = bannerList.find((x) => x.pos === pos);
    return block;
  };

  const doSearch2State = useDoSearch2(
    searchParameters,
    useCallback(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, [])
  );

  var locationsState = [...doSearch2State.locations];

  useEffect(() => {
    setMarkerSelected(-1);
  }, [doSearch2State]);

  const getEcoATMAboutData = useMemo(() => {
    if (!thirdpartyId) {
      return {
        state: getStateNameByAbbr(searchParameters.state, region1List),
        stateAbbr: searchParameters.state,
        city: searchParameters.city,
        locationName: "",
      };
    }
    if (thirdpartyId && locationState.location.Id > 0) {
      console.log("locationState.location: ", locationState.location);
      return {
        state: locationState.location.StateName,
        stateAbbr: locationState.location.StateAbbr,
        city: locationState.location.City,
        locationName: locationState.location.Name,
      };
    }

    return {
      state: "",
      stateAbbr: "",
      city: "",
      locationName: "",
    };
  }, [region1List, searchParameters]);

  useEffect(() => {
    setThirdpartyId(thirdparty);
    window.scrollTo(0, 0);
  }, [thirdparty]);

  const autoComplete = useRef();

  useEffect(() => {
    console.log("My coordinates", coordinates, state, city);
    if (thirdparty) return;
    // console.log("state", state);
    if (!(state || city) && coordinates.latitude && coordinates.longitude) {
      // console.log("Set Search Parameters to Latitude and Longitude");
      setSearchParameters({
        countryId: 1,
        state: null,
        city: null,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        pageSize: 20,
        userIPAddress: coordinates?.ip,
      });
    }
  }, [state, city, thirdparty, coordinates, i18n.language]);

  useEffect(() => {
    console.log("My State and City", state, city, thirdparty);
    if (thirdparty) return;
    // console.log("state", state);
    if ((state || city) && coordinates?.ip) {
      // console.log("Set Search Parameters to State and City");
      if (autoComplete.current) {
        autoComplete.current.clearGoogleAddress();
      }
      setSearchParameters({
        countryId: 1,
        state: state,
        city: city,
        latitude: null,
        longitude: null,
        pageSize: 20,
        userIPAddress: coordinates?.ip,
      });
    }
  }, [state, city, coordinates?.ip, thirdparty, i18n.language]);

  useEffect(() => {
    // country list 1 = US
    const getCountryStatesList = async () => {
      const stateList = await GetRegion1List(1);
      setRegion1List(stateList);
    };

    getCountryStatesList();

    const getGoogleMapKey = async () => {
      if (!googleMapKey) {
        const response = await findClientMappingSettings();
        setGoogleMapKey(response.MappingSettings[0].APIKey);
      }
    };
    getGoogleMapKey();
  }, []);

  const onClickMarketMap = (markerID) => {
    if (markerSelected !== markerID) setMarkerSelected(markerID);
  };

  const { Track } = useTracking({
    page: "listMapLocator",
    searchReqLogId: doSearch2State.doSearch2SearchReqLogId,
  });

  const locationsList =
    locationsState.length > 0 && !thirdpartyId
      ? locationsState
      : thirdpartyId && locationState.location.Id > 0
      ? [locationState.location]
      : [];

  // const { i18n } = props;
  // console.log("Current Languate", i18n.language);

  useEffect(() => {
    setExtraDataByLanguage(getExtraDataByLanguage());

    const setMenuConfigurationItems = async (language) => {
      const response = await getMenuItems(language);
      setMenuItems(response);
    };

    //WS to get images from json in public
    const setBannerItems = async (language) => {
      const response = await getBannerItems(language);

      let tempBanners = [...response];

      const cookies = new Cookies();
      // cookies.set("ecoATM_top_banner", "{"images":d{"url":"#","visible": true,"small":"https://picsum.photos/600/300","medium":"https://picsum.photos/665/150","large":"https://picsum.photos/1200/150"}}" )
      let cookie = cookies.get(process.env.REACT_APP_COOKIE_NAME);

      if (cookie && cookie.images) {
        tempBanners[2] = cookie.images;
      }

      const search = props.location.search;
      const params = new URLSearchParams(search);
      const utm_content = params.get("utm_campaign");

      let bannerHTML = tempBanners.find((x) => x.id === utm_content);

      if (bannerHTML) {
        let customBanner = tempBanners.find((x) => x.pos === bannerHTML.pos);
        if (customBanner) {
          customBanner.large = bannerHTML.large;
          customBanner.medium = bannerHTML.medium;
          customBanner.small = bannerHTML.small;
          customBanner.isHTML = bannerHTML.isHTML;
          customBanner.visible = bannerHTML.visible;
        }
      }

      setBannersImages({
        bannerOne: getBannerByPos(tempBanners, 1),
        bannerTwo: getBannerByPos(tempBanners, 2),
        bannerThree: getBannerByPos(tempBanners, 3),
      });
    };
    setMenuConfigurationItems(i18n.language);
    /** get the cookie for the banner if exists or setted */
    setBannerItems(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <EcoATMSEO
        state={searchParameters.state}
        city={searchParameters.city}
        region1List={region1List}
        seoTitle={
          thirdpartyId && locationState.location.Id > 0
            ? locationState.location.RestLocationSEO?.SEOTitle
            : ""
        }
        seoMetaDescription={
          thirdpartyId && locationState.location.Id > 0
            ? locationState.location.RestLocationSEO?.MetaDescription
            : ""
        }
      />
      <Track>
        <div className="container-fluid container-lg">
          {false && (
            <div
              className="fixed-bottom-right mr-4"
              style={{ marginBottom: 1 }}
            >
              <LanguageSwitcher />
            </div>
          )}
          <div className="row">
            <div className="col-12"></div>
            <div className="col-12 bl-no-gutter-sm">
              {bannersImages.bannerOne && bannersImages.bannerOne.visible && (
                <div className="card shadow-sm p-0 mt-2">
                  {bannersImages.bannerOne.isHTML && (
                    <AnnouncementCustom
                      customClass=""
                      content={bannersImages.bannerOne.large}
                    />
                  )}
                  {bannersImages.bannerOne.isHTML === false && (
                    <ResponsiveBanner
                      url={bannersImages.bannerOne.url}
                      smallImage={bannersImages.bannerOne.small}
                      mediumImage={bannersImages.bannerOne.medium}
                      largeImage={bannersImages.bannerOne.large}
                    />
                  )}
                </div>
              )}

              {/* <div className="card shadow-sm p-0 mt-1 d-none d-lg-block">
                <EcoMenu menuConfiguration={menuItems} />
              </div>
              <div className="card shadow-sm p-0 mt-1 d-block d-lg-none">
                <MobileMenu menuItems={menuItems} />
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12 bl-no-gutter-sm">
              {bannersImages.bannerTwo && bannersImages.bannerTwo.visible && (
                <div className="card shadow-sm p-0 ">
                  {bannersImages.bannerTwo.isHTML && (
                    <AnnouncementCustom
                      customClass=""
                      content={bannersImages.bannerTwo.large}
                    />
                  )}
                  {bannersImages.bannerTwo.isHTML === false && (
                    <ResponsiveBanner
                      url={bannersImages.bannerTwo.url}
                      smallImage={bannersImages.bannerTwo.small}
                      mediumImage={bannersImages.bannerTwo.medium}
                      largeImage={bannersImages.bannerTwo.large}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {doSearch2State.loading && (
              <div className="col-12 d-block" style={{ height: 730 }}>
                <Loading />
              </div>
            )}
            <div className="col-12 pr-1 pl-2 px-md-3">
              {googleMapIsReady && (
                <GoogleAutocomplete
                  ref={autoComplete}
                  onGetLatLng={(result) => {
                    setCoordinates({
                      latitude: result.latitude,
                      longitude: result.longitude,
                      ip: coordinates?.ip,
                    });
                    props.history.replace("/");
                  }}
                ></GoogleAutocomplete>
              )}
            </div>
            {!doSearch2State.loading && (
              <>
                <div className="col-12 col-md-6 col-lg-5 pr-0 pl-2 px-md-3  listmaplocator__locationListContainer ">
                  <LocationListItems
                    locations={locationsList}
                    locationSelected={markerSelected}
                    itemContainerClass="mr-2"
                    onClick={onClickMarketMap}
                    showAccordion={locationsList.length > 1}
                    showLandingPageLink={locationsList.length > 1}
                    getContainerSize={(height) => {
                      if (height > 886) {
                        height = 395;
                      }

                      if (height === 0) height = 475;

                      // remove 16 because the mb-3 on the child div
                      if (locationsList.length > 1) {
                        mapContainer.current.style.height = height + "px";
                      } else {
                        mapContainer.current.style.height = height - 16 + "px";
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-7 listmaplocator__locationMapContainer bl-no-gutter-sm">
                  <div
                    ref={mapContainer}
                    className="card shadow-sm p-0  rounded-1 mb-3"
                    style={{ height: 886 }}
                  >
                    {/* {!loadMap && isMobile && (
                  <img
                    style={{ height: "100%" }}
                    onMouseOver={handlerMouseOver}
                    onTouchStart={handlerMouseOver}
                    src={generateStaticMap(locationsState)}
                    alt="map"
                  ></img>
                )} */}
                    {!(navigator.userAgent.indexOf("Chrome-Lighthouse") > -1) &&
                      (!doSearch2State.loading || thirdpartyId) &&
                      googleMapKey && (
                        <GoogleMap
                          markers={locationsList}
                          googleApikey={googleMapKey}
                          onClick={onClickMarketMap}
                          markerSelected={markerSelected}
                          onMapReady={() => {
                            setGoogleMapIsReady(true);
                          }}
                          containerRef={mapContainer}
                        />
                      )}
                  </div>

                  <small className="float-right mt-n3 mr-1 mb-3">
                    <a
                      href="https://www.google.com/policies/privacy/"
                      rel="noreferrer"
                      target="_blank"
                      className=""
                    >
                      Privacy Policy
                    </a>
                  </small>
                </div>
              </>
            )}
          </div>

          <div className="row">
            <div className="col-12 bl-no-gutter-sm">
              {bannersImages.bannerThree &&
                bannersImages.bannerThree.visible && (
                  <div className="card shadow-sm p-0 mt-2">
                    {bannersImages.bannerThree.isHTML && (
                      <AnnouncementCustom
                        customClass=""
                        content={bannersImages.bannerThree.large}
                      />
                    )}
                    {bannersImages.bannerThree.isHTML === false && (
                      <ResponsiveBanner
                        url={bannersImages.bannerThree.url}
                        smallImage={bannersImages.bannerThree.small}
                        mediumImage={bannersImages.bannerThree.medium}
                        largeImage={bannersImages.bannerThree.large}
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
          {extraDataByLanguage && extraDataByLanguage.howItWorks && (
            <div className="row">
              <div className="col-12">
                <div
                  className="card shadow-sm p-0 mt-3"
                  style={{ height: 730 }}
                >
                  <VideoPlayer
                    title={extraDataByLanguage.howItWorks.title}
                    light={extraDataByLanguage.howItWorks.imagePreview}
                    titleCssClass="display-4 text-center font-weight-bold mt-3 mb-4"
                    autoPlaying={true}
                    videoList={extraDataByLanguage.howItWorks.videoList}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {extraDataByLanguage && extraDataByLanguage.ecoAtmShowcase && (
          <div className="container-fluid container-lg">
            <div className="row">
              <div className="col-12">
                <div className="card shadow-sm mt-3 p-3 ecoatm-background-1">
                  <Showcase
                    itemCssClass="text-center listmaplocator__showcase--item py-1 px-3"
                    items={extraDataByLanguage.ecoAtmShowcase}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm p-3 mt-3">
                <AboutEcoAtm
                  state={getEcoATMAboutData.state}
                  stateAbbr={getEcoATMAboutData.stateAbbr}
                  city={getEcoATMAboutData.city}
                  locationName={getEcoATMAboutData.locationName}
                />
              </div>
              {thirdpartyId && locationState.location.Id > 0 && (
                <div className="card shadow-sm p-3 mt-3">
                  <div className="px-0 px-md-5">
                    <h4 className="bullseye-red">
                      {t("nearbyLocations.title")}
                    </h4>
                    <div className="container-fluid">
                      <LocationListItems
                        locations={locationsState.slice(1, 4)}
                        showAccordion={true}
                        parentContainerClass="row"
                        itemContainerClass="col-12 col-lg-4 d-flex px-2"
                        showLandingPageLink={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="card shadow-sm p-3 mt-3 ecoatm-background-2">
                <SimpleListWithTitle
                  title={t("browser.browseByState")}
                  items={region1List.map((state) => {
                    return {
                      id: state.Abbr,
                      title: state.Name,
                      url: `/${state.Abbr?.toLowerCase()}`,
                    };
                  })}
                  numberOfColumns={4}
                  containerCssClass="listmaplocator__statelist--container px-1 px-md-5"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid">
          <div className="row main-footer mt-3">
            <div className="col-12">
              <EcoAtmFooter />
            </div>
          </div>
        </div> */}
      </Track>
      <Helmet>
        <link
          rel="canonical"
          href={
            thirdpartyId && locationState.location.Id > 0
              ? `https://${
                  process.env.REACT_APP_US_HOST
                }/${locationState?.location?.StateAbbr?.toLowerCase()}/${locationState?.location?.City?.toLowerCase().replace(
                  / /g,
                  "-"
                )}/sell-my-phone-${locationState?.location?.City?.toLowerCase()?.replace(
                  / /g,
                  ""
                )}-${locationState?.location?.StateAbbr?.toLowerCase()}-${locationState?.location?.ThirdPartyId?.toLowerCase()}.html`
              : `https://${process.env.REACT_APP_US_HOST}${
                  state ? `/${state?.toLowerCase()}` : ""
                }${city ? `/${city.toLowerCase().replace(/ /g, "-")}` : ""}`
          }
        />
      </Helmet>
    </>
  );
};

export default ListMapLocator;

import React from "react";

import { useTranslation } from "react-i18next";
const EcoAtmFooterMenuItems = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-around flex-column flex-md-row text-white">
      <div>
        <ul className="list-unstyled  text-md-left text-center">
          <li className="my-2">
            <a href="https://locations.ecoatm.com/?_ga=2.81995222.1949369145.1580409308-634817045.1578956313">
              {t("footer.findAKiosk")}
            </a>
          </li>
          <li className="my-2">
            <a href="https://www.ecoatm.com/pages/smartphone-safety">
              {t("footer.cellPhoneSecurityTips")}
            </a>
          </li>
          <li className="my-2">
            <a href="https://www.ecoatm.com/pages/frequently-asked-questions">
              {t("footer.FAQs")}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <ul className="list-unstyled text-md-left text-center">
          <li className="my-2">
            <a href="https://www.ecoatm.com/pages/how-it-works">
              {t("footer.howItWorks")}
            </a>
          </li>
          <li className="my-2">
            <a href="https://www.ecoatm.com/pages/helping-the-environment">
              {t("footer.helpingTheEnvironment")}
            </a>
          </li>
          <li>
            <a href="https://www.ecoatm.com/pages/accessibility-statement">
              {t("footer.accessibilityStatement")}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <ul className="list-unstyled  text-md-left text-center">
          <li className="my-2">
            <a href="https://www.ecoatm.com/pages/how-to-prepare-old">
              {t("footer.howToPrepareYourDevice")}
            </a>
          </li>
          <li className="my-2">
            <a href="https://www.ecoatm.com/pages/contact-us">
              {t("footer.contact")}
            </a>
          </li>
          <li className="my-2">
            <a className="ot-sdk-show-settings" style={{ cursor: "pointer" }}>
              {t("footer.CADoNotSellMyPersonalInformation")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default EcoAtmFooterMenuItems;

import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { LogSearchRsltwReq } from "./services/restSearchService";

import { ToastContainer } from "react-toastify";
import ListMapLocator from "./components/pages/listMapLocator";
import PromoLocator from "./components/pages/promoLocator";
import track from "react-tracking";
import ErrorPage from "./components/pages/errors/errorPage";

function App2() {
  console.log("app start....");
  return (
    <>
      <ToastContainer />
      <Switch>
        <Route
          path="/promo/:thirdpartyid([A-Za-z0-9]+)"
          component={PromoLocator}
        />

        <Route
          path="/error/:errorType(not-found|unexpected)"
          component={ErrorPage}
        />

        <Route
          path="/([A-Za-z]{2})/([A-Za-z-]+)/sell-my-phone-([A-Za-z]+)-([A-Za-z]{2})-:thirdpartyid(\d+).html"
          component={ListMapLocator}
        />
        <Route path="/:state([A-Za-z]{2})/:city?" component={ListMapLocator} />
        <Route path="/" component={ListMapLocator} />
      </Switch>
    </>
  );
}

export default track(
  { app: "EcoATM-Locator" },
  {
    dispatch: (data) => {
      LogSearchRsltwReq(
        data.locationId,
        data.responseType,
        data.searchReqLogId,
        data?.attributeId,
        data?.attributeTypeId
      );
    },
  }
)(App2);

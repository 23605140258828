import http from "./httpService";

const endpointPath = "RestEvent.svc";
const categoryGetMethod = "GetEvent";

function getEventURL(ThirdPartyID) {
  return `${endpointPath}/${categoryGetMethod}?ThirdPartyID=${ThirdPartyID}`;
}

export async function getEvent(thirdPartyId) {
  http.setSecurityParameters();

  const event = await http.get(getEventURL(thirdPartyId));
  return event.data;
}

import React from "react";
import BusinessHoursTable from "./businessHoursTable";

const BusinessHoursExpanded = ({
  title,
  daysArray,
  smallAlert,
  isClose,
  alertText,
}) => {
  return (
    <div>
      <h4 className="bullseye-red">{title}</h4>
      <small>{smallAlert}</small>
      <div className="alert my-1" role="alert">
        <span
          className={
            isClose
              ? "font-weight-bold bullseye-red"
              : "font-weight-bold bullseye-green"
          }
        >
          {alertText}
        </span>
      </div>
      <BusinessHoursTable daysArray={daysArray} />
    </div>
  );
};

export default BusinessHoursExpanded;

import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./breadcrumb.scss";

const breadcrumb = ({ items }) => {
  return (
    <>
      <Breadcrumb>
        {items.map((item, index) => (
          <Breadcrumb.Item
            key={index}
            linkAs={Link}
            linkProps={{ to: item.url }}
            active={items.length === index + 1}
          >
            {item.displayText}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </>
  );
};

export default breadcrumb;
